.wrapper {
  opacity: 0;
  transform: translateY(5px);

  &.isAnimating {
    animation: appear 500ms ease-in-out forwards 20ms;

    @keyframes appear {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &.isMounted {
    opacity: 1;
    transform: translateY(0);
  }
}
