@import '../../sass/variables';

.wrapper {
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    content: '';
    background: $noisePattern;
    background-color: $colorGray100;
    background-repeat: repeat;
    background-size: 47px 47px;
  }

  &.start::after {
    top: 0;
  }

  &.end::after {
    bottom: 0;
  }

  &.half::after {
    height: 50%;
  }

  &.quarter::after {
    height: 25%;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}
