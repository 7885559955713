@import '../../../../sass/variables';

.point {
  position: absolute;
  width: 1px;
  height: 1px;
}

.line {
  width: 120px;
  height: 1px;
  background: $colorBlue700;
  opacity: 0.16;
  transform: translateX(-100%);
}

.container {
  position: relative;
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease-in-out 300ms;

  &.isVisible {
    opacity: 1;
  }
}
