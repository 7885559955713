@import '../../../../sass/variables';

.wrapper {
  position: relative;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
}

.image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  max-width: none;
  height: 100%;
  opacity: 0.7;
  object-fit: fill;
}

@include media-breakpoint-up(sm) {
  .image {
    opacity: 1;
  }
}
