@import '../../sass/variables';

.wrapper {
  position: relative;
  padding: 15px 10px;
  color: $colorGray900;
  border-bottom: 4px solid $colorGray200;
}

.button {
  display: inline-flex;
  transition: transform 300ms ease-in-out;

  &:hover,
  &:focus {
    color: $colorBlue300;
    text-decoration: none;
  }
}

.isActive {
  position: relative;
  color: $colorBlue300;
  border-color: $colorBlue300;
}

// Theme
.primary {
  font-weight: $weight-normal;

  .isActive & {
    font-weight: $weight-medium;
  }
}

.secondary {
  font-size: 0.9375rem;
  font-weight: $weight-medium;
}

@include media-breakpoint-up(md) {
  .wrapper {
    padding: 0;
    border: none;

    &.primary,
    &.secondary {
      &::before {
        position: absolute;
        top: 50%;
        left: -20px;
        display: block;
        width: 4px;
        height: 16px;
        content: '';
        background: currentColor;
        transition: transform 300ms ease-in-out;
        transform: translate(-200%, -50%) scaleX(0);
      }
    }

    &.isActive {
      &::before {
        transform: translate(-100%, -50%) scale(1);
      }
    }
  }

  // Theme
  .secondary {
    font-size: 1.125rem;
  }
}
