@import '../../../../../sass/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 950px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 16px;
}

.list {
  padding: 0 30px;
  margin: 0;
  list-style-type: none;
}

.listItem {
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    top: 12px;
    left: -30px;
    width: 14px;
    height: 14px;
    content: '';
    background: $colorYellow200;
    border-radius: 2px;
  }
}

.imageWrapper {
  display: none;
}

@include media-breakpoint-up(md) {
  .wrapper {
    flex-direction: row;
    gap: 80px;
    align-items: center;
    justify-content: space-between;
    max-width: 950px;
    padding: 60px 0;
    margin: 0 auto;

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  .list {
    max-width: 435px;
  }

  .listItem {
    &::before {
      top: 1.1em;
      left: -35px;
    }
  }

  .imageWrapper {
    display: block;
    width: 300px;
    height: 350px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 12px;
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    max-width: 340px;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    gap: 90px;
    max-width: 960px;
  }

  .imageWrapper {
    width: 437px;
    height: 569px;
    margin: 0 auto;
  }

  .listItem {
    margin-bottom: 60px;
  }
}
