@import '../../sass/variables';

.tabbarWrapper {
  display: flex;
}

.enterActive {
  opacity: 0;
}

.exitActive {
  opacity: 1;
}

.enterDone {
  opacity: 1;
}

.exitDone {
  opacity: 0;
}

.enterActive,
.exitActive {
  transition: opacity 500ms;
}

.spacing-sm {
  margin-bottom: 30px !important;
}

.spacing-md {
  margin-bottom: 30px !important;
}

.spacing-lg {
  margin-bottom: 50px !important;
}

@include media-breakpoint-up(md) {
  .spacing-sm {
    margin-bottom: 50px !important;
  }

  .spacing-md {
    margin-bottom: 60px !important;
  }

  .spacing-lg {
    margin-bottom: 70px !important;
  }
}

@include media-breakpoint-up(lg) {
  .spacing-sm {
    margin-bottom: 60px !important;
  }

  .spacing-md {
    margin-bottom: 75px !important;
  }

  .spacing-lg {
    margin-bottom: 90px !important;
  }
}
