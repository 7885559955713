@import '../../sass/variables';

.card {
  position: relative;
  display: inline-block;
  text-decoration: none;
  background: $colorWhite;
  border-radius: 12px;
}

// Themes
.primary {
  border: 1px solid $colorGray200;
}

.secondary {
  border: 1px solid $colorGray200;
  box-shadow: 0 20px 35px 0 rgba($colorGray900, 0.05);
}

@media (max-width: 768px) {
  .card {
    padding: 30px 20px !important;
  }
}
