@import '../../../sass/variables';

.tabsRow {
  position: relative;
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow-x: auto;
  list-style-type: none;

  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: $colorGray200;
  }

  &.transparent-border {
    &::after {
      display: none;
    }
  }

  // WIDTHS
  &.full {
    display: flex;
    width: 100%;

    .tabWrapper {
      flex-grow: 1;
    }

    .button {
      width: 100%;
    }
  }

  &.auto {
    display: inline-flex;
  }
}

.tabWrapper {
  color: $colorGray500;
}

.button {
  position: relative;
  z-index: 1;
  display: block;
  white-space: nowrap;
  border-bottom: 2px solid $colorGray200;
  transition: border 300ms ease-in-out, color 300ms ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
    border-color: rgba($colorGray400, 0.3);
  }

  &.isActive {
    color: $colorBlue300;
    border-color: $colorBlue300;
  }

  &.transparent-border {
    border-bottom: 2px solid transparent;

    &.isActive {
      border-bottom: 2px solid $colorBlue300;
    }
  }

  // SIZES
  &.size-md {
    padding: 20px 10px;
  }

  &.size-lg {
    padding: 15px 45px;
  }
}

@include media-breakpoint-up(md) {
  .button {
    font-size: 1.125rem;
  }

  .tabsRow {
    justify-content: space-between;
    width: 100%;
    max-width: 740px;
  }
}
