@import '../../../../../sass/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@include media-breakpoint-up(sm) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .title {
    margin-bottom: 6px;
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 100px 20px;
  }

  .title {
    margin-bottom: 18px;
  }
}
