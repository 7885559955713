@import '../../sass/variables';

.nav {
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  list-style: none;
}

.item {
  flex-shrink: 0;
}

@include media-breakpoint-up(md) {
  .nav {
    flex-basis: 200px;
    flex-shrink: 0;
    max-width: 200px;
    margin: 0 50px 0 0;
  }

  .item {
    margin-bottom: 45px;

    &:last-child {
      margin: 0;
    }
  }

  .wrapper {
    display: flex;
  }

  .list {
    display: block;
    width: 100%;
    overflow: visible;
    background: transparent;
  }

  .content {
    flex-basis: auto;
    flex-grow: 1;
    min-height: 420px;
  }
}

@include media-breakpoint-up(lg) {
  .nav {
    flex-basis: 260px;
    max-width: 260px;
    margin-right: 80px;
  }
}
