@import '../../../../../sass/variables';

.executivesWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.cardText {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}

.image {
  width: 105px;
  height: 100px;
}

.imageWrapper {
  flex-shrink: 0;
}

@include media-breakpoint-up(sm) {
  .executivesWrapper {
    display: grid;
    grid-template-columns: repeat(2, 270px);
    grid-auto-rows: 350px;
    gap: 35px;
  }

  .card {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }

  .cardText {
    width: 100%;
    padding: 20px;
  }

  .image {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-up(md) {
  .executivesWrapper {
    grid-auto-rows: 400px;
  }

  .cardText {
    padding: 20px 30px;
  }
}

@include media-breakpoint-up(lg) {
  .executivesWrapper {
    grid-template-columns: repeat(3, 270px);
  }
}
